.google {
    position: relative;
    border-top: 100px solid #EA4335;
    border-right: 100px solid #4285F4;
    border-bottom: 100px solid #34A853;
    border-left: 100px solid #FBBC05;
    border-radius: 50%;
    background-color: #FFFFFF;
    width: 300px;
    height: 300px;
    padding: 0;
    margin: 10vh auto 0;
  }
  
  .google::before {
    content: "";
    position: absolute;
    top: 50%;
    right: -95px;
    transform: translateY(-50%);
    width: 245px;
    height: 100px;
    background: #4285F4;
  }
  
  .google::after {
    content: "";
    position: absolute;
    border-top: 200px solid transparent;
    border-right: 200px solid #FFFFFF;
    top: -100px;
    right: -100px;
    width: 0;
    height: 0;
  }