.cardTestim {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    position: relative;
}

.circle::before {
    content: ', ,';
    color: #FFF;
    font-size: 30px;
    font-weight: bold;
    line-height: 1;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #01562b;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
}